.p-tabview, .p-tabview-panels {
    padding: 0px !important;
}

#table-plus-content {
    display: flex;
}

.slide-enter {
    transform: translateY(100%);
}

.slide-enter-active {
    transform: translateY(0%);
    transition: transform 300ms;
}

.slide-exit {
    transform: translateY(0%);
}

.slide-exit-active {
    transform: translateY(100%);
    transition: transform 300ms;
}

.slide1-enter {
    transform: translateX(100%);
}

.slide1-enter-active {
    transform: translateX(0%);
    transition: transform 300ms;
}

.slide1-exit {
    transform: translateX(0%);
}

.slide1-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
}

.table-container {
    transition: width 0.3s ease, transform 0.3s ease  0.1s;
}

.menu-open .table-container {
    width: calc(100% - 300px);
    transform: translateX(0);
}

.p-overlaypanel .p-overlaypanel-content:has(.scan-doc-link) {
    padding: 0px;
}

.row-button {
    transition: background-color 0.3s ease !important;
}

.row-button:hover {
    background-color: #3aaf4a !important;
}

.import-export-table > .p-datatable-wrapper {
    max-height: 55vh;
}

.status-table > .p-datatable-wrapper {
    max-height: 25rem;
}