.doc-sec {
    display: flex;
    flex-direction: column;
}

.card-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.doc-num {
    font-size: 18px;
    margin: 0px;
    font-weight: bold;
}

.doc-title {
    margin: 0px;
}

.doc-button {
    margin-top: 15px !important;
}

.p-card .p-card-content {
    padding: 0px !important;
}

.total-doc {
    display: flex;
    justify-content: space-between;
}

#keyword-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
}

#keyword-button, .p-button {
    padding: 0.75rem 0.5rem;
}

.chip-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

#middle-sect {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

#docCards > div > div > .p-card-body {
    padding: 0px;
}

#docCards > div > div > div > .p-card-title, #docCards > div > div > div > .p-card-content > .card-text {
    padding: 1rem 1.25rem 1.25rem 1.25rem;
}

.p-card.p-component.support-doc-card > div > .p-card-title  {
    padding: 1rem 1.25rem 1.25rem 1.25rem;
}

.support-card-text {
    padding: 1rem 1.25rem 1.25rem 1.25rem;
}

.card-doc-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EBF0F5;
    padding: 0px 15px;
    font-size: 1.1em;
    color: #043d75;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out;
    border-radius: 0 0 6px 6px;

}

.card-doc-link:hover {
    background-color: #043d75;
  }

.card-doc-link-text {
    transition: color 0.3s ease-in-out;
}

.card-doc-link:hover .card-doc-link-text {
    color: white;
}

.card-doc-link i {
    transition: transform 0.3s;
}

.card-doc-link:hover i {
    transform: translateX(10px);
}

.support-card-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 15px;
    height: 5.5em;
}

.doc-sec-skel {
    display: flex;
    gap: 7px;
    flex-direction: column;
    /* max-height: 30vh; */
}

.card-doc-link-skel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EBF0F5;
    padding: 0px 15px;
    font-size: 1.1em;
    color: #043d75;
    font-weight: bold;
    border-radius: 0 0 6px 6px;
}

.card-doc-link-text-skel {
    margin: 15px 0px;
}

#skel-doc-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.docCardItemSkel {
    flex-basis: 22%;
}