.breadcrumbSect {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: space-between;
}

#bread-toggle-tall > .breadcrumbSect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 1500px) {
    #bread-toggle-tall > .breadcrumbSect {
        flex-direction: column;
        align-items: flex-start;
    }

    #bread-toggle-tall {
        height: 190px !important;
    }
}

@media (max-width: 1100px) {

    #bread-toggle-tall {
        height: 200px !important;
    }
}

@media (max-width: 950px) {

    #bread-toggle-tall {
        height: 350px !important;
    }
}

#toggle-and-create-sect {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
}

#bread-toggle {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .breadcrumbSect {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 950px) {
    #toggle-and-create-sect {
        flex-direction: column;
        align-items: flex-start;
    }
}

#bread-toggle-tall {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 130px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

#bread-crumb-menu {
    display: flex;
    flex-direction: row;
}

.icons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.icons > .p-selectbutton > .p-button {
    height: 47px;
}

#toggle-icons {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
    margin-bottom: 1px;
}

#bread-db {
    display: flex;
    gap: 15px;
    flex-direction: column;
    margin-bottom: 1px;
    align-items: flex-end;
}

@media (max-width: 1200px) {
    #toggle-icons {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    #bread-toggle {
        flex-wrap: wrap;
    }
    #bread-db {
        align-items: flex-start;
    }
    #bread-toggle-tall {
        flex-direction: column;
        height: 175px;
    }
} 

@media (max-width: 900px) {
    #data-table-buttons {
        align-items: flex-start;
        flex-direction: column;
    }
}

#data-table-buttons {
    display: flex;
    gap: 12px;
}

#data-source-toggle {
    display: flex;
    justify-content: flex-end;
}

#column-select {
    width: 10vw;
}

#column-select-items {
    display: flex;
    flex-direction: column;
    gap: 5px
}

#filter-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-top: 30px;
}

#filter-buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#move-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
}

#confirmation-text {
    font-size: 18px;
    margin-bottom: 0px;
}

.p-menuitem > a > p {
    margin: 0px;
}