.doc-opt {
    margin: 6px 0px;
}

.doc-opt-proj {
    margin: 4px 0px;
}

.remove-opt {
    color: red;
    cursor: pointer;
}

.remove-opt-sort {
    color: red;
    cursor: pointer;
    position: relative;
    padding-bottom: 4px;
}

.remove-opt-sort::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.3s, background-color 0.3s;
}

.remove-opt-sort:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
    background-color: red;
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0 !important;
}

.station-folder {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 3px 2px 3px 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    flex-basis: 25%;
}

.child-station-folder {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 3px 2px 3px 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.folder-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}

.folder-and-icon {
    display: flex;
    gap: 10px;
    align-items: center;
}

.child-folder-and-icon {
    display: flex;
    gap: 10px;
    align-items: center;
}

.menu-link {
    cursor: pointer;
    color: #043d75;
    transition: color 0.3s;
}

.menu-link:hover {
    color: red;
}

.menu-link-sort {
    cursor: pointer;
    color: #043d75;
    position: relative;
    padding-bottom: 4px;
}

.menu-link-sort::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.3s, background-color 0.3s;
}

.menu-link-sort:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
    background-color: #043d75;
}

#button-plus-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#data-tab {
    display: flex;
    margin-top: 5px;
    /* max-height: 60vh;
    overflow: auto; */
}

#data-tab-upper {
    display: flex;
    margin-top: 60px;
}

.sort-menu-items {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.sort-menu-divider.p-divider.p-divider-horizontal {
    margin: 5px 0px !important;
}

.folder-list-proj-doc {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}

.group-root-list {
    max-height: calc(100vh - 300px);
    overflow: auto;
}

.folder-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.button-plus-loading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#move-form-folder {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.move-header {
    display: flex;
    align-items: center;
    gap: 15px;
}

#data-tab-icons {
    display: flex;
}

.group-table > .p-datatable-wrapper {
    max-height: calc(100vh - 300px);
} 

@media (max-width: 1200px) {
    .group-table > .p-datatable-wrapper {
        max-height: calc(100vh - 400px);
    }
}

@media (max-width: 1200px) {
    .group-table > .p-datatable-wrapper {
        max-height: calc(100vh - 400px);
    }
}

@media (max-width: 950px) {
    .group-table > .p-datatable-wrapper {
        max-height: calc(100vh - 500px);
    }
}
