#login-form {
    margin-top: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #043d75;
}

#password > input, #conpassword > input {
    width: 40vw;
}

.login-text {
    color: #043d75;
    margin: 0px;
}

#remember-group {
    display: flex;
    gap: 15vw;
    align-items: center;
}

#enroll-group {
    display: flex;
    gap: 2vw;
    align-items: center;
}

.login-link {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

#login-title {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.login-link {
    position: relative;
    text-decoration: none;
}
  
.login-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #043d75;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.3s ease;
}
  
.login-link:hover::before {
    transform: scaleX(1);
}

#below-password {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    margin-top: 5px;
}

.login-label {
    display: flex;
    justify-content: space-between;
}

#sign-in-button {
    width: 40vw;
    transition: background-color 0.3s;
}
  
#sign-in-button:hover {
    background-color: #3AAF4A;
}

#newpassSignup > input, #connewpassSignup > input {
    width: 100%;
}