#database-table .p-column-title  {
    font-size: 0.9rem !important;
}

#database-table .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 0.30rem !important;
    font-size: 0.9rem !important;
}

#database-table .p-datatable .p-datatable-thead > tr > th {
    padding: 1rem 0.30rem !important;
}

.doc-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
    FLEX-BASIS: 15%;
}

.doc-item-list {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
}

#icon-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 15px;
}

.check-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2rem;
}

.doc-item.selected {
    background-color: #E8EAEB;
    border: 1px solid #b5b7b8;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.doc-item:hover {
    background-color: #F5F5F5;
    border-radius: 8px;
    cursor: pointer;
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 0.3rem !important;
}

.p-toast-message-content {
    display: flex;
    align-items: center !important;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.toast-p {
    margin-top: 14px;
}

.toast-flex-item {
    display: flex;
    align-items: center;
    gap: 4px;
}

.doc-item-list > .p-toast {
    max-width: 48rem;
}

#edit-buttons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
}

.doc-item > p::selection {
    background-color: transparent;
    color: inherit;
}

#pdf-viewer-dialog {
    width: 50vw;
}

.title-preview-link {
    text-decoration: underline;
}

.pdf-buttons {
    display: flex;
    gap: 10px;
    margin: 5px 0px 10px 0px;
}

.table-titles {
    font-size: 20px;
    color: #708DA9;
    text-decoration: underline;
    margin-top: 5px;
    margin-bottom: 10px;
}

.table-title-icon {
    font-size: 20px;
    color: #708DA9;
    text-decoration: underline;
    margin-top: 5px;
    margin-bottom: 5px;
}

#icon-list-view {
    width: 100%;
}

.folder-paginator{
    width: 100%;
}

#folder-loading-section{
    display: flex;
    flex-direction: column;
}

#folder-tabview-container{
    width: 100%;
}

.database-table > .p-datatable-wrapper {
    max-height: calc(100vh - 320px);
}

@media (max-width: 1200px) {
    .database-table > .p-datatable-wrapper {
        max-height: calc(100vh - 400px);
    }
}

@media (max-width: 950px) {
    .database-table > .p-datatable-wrapper {
        max-height: calc(100vh - 560px);
    }
}

.doc-data-tab {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
}

@media (max-width: 1500px) {
    .doc-data-tab {
        max-height: calc(100vh - 310px);
    }
}

@media (max-width: 1360px) {
    .doc-data-tab {
        max-height: calc(100vh - 340px);
    }
}

@media (max-width: 1200px) {
    .doc-data-tab {
        max-height: calc(100vh - 330px);
    }
}

@media (max-width: 950px) {
    .doc-data-tab {
        max-height: calc(100vh - 480px);
    }
}