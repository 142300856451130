.custom-menubar {
  justify-content: space-between;
}

.menu-bar-logo {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  font-size: 20px;
}

#menu-left {
  display: flex;
  align-items: center;
  gap: 50px;
}

.p-menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.p-menubar .p-menuitem-link {
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.sticky-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

#search-results-container {
  margin-top: 10px;
}

.search-header {
  text-decoration: underline;
  margin: 10px 0px;
  font-weight: bold;
}

.main-logo {
  display: flex;
  align-items: flex-end;
  gap: 15px;
}

.logo-link {
  cursor: pointer;
}

li > a {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.p-panelmenu .p-menuitem-link {
  align-items: flex-start !important;
}