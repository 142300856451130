.admin-action {
    display: flex;
    justify-content: space-around;
}

.action-button {
    width: 45%;
}

.edit-user-info {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
}

.edit-user-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 5px;
}

.edit-project-form {
    margin-top: 10px;
}

.access-button {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    margin: 0px;
}

.user-table > .p-datatable-wrapper {
    max-height: calc(100vh - 290px);
}

@media (max-width: 1200px) {
    .user-table > .p-datatable-wrapper {
        max-height: calc(100vh - 300px);
    }
}

@media (max-width: 1100px) {
    .user-table > .p-datatable-wrapper {
        max-height: calc(100vh - 310px);
    }
}

.assigned-table > .p-datatable-wrapper {
    max-height: 17rem;
}