#doc-item-toast {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #E4F2E1;
    background: #eff3f8;
    padding: 0px 20px;
    border: 1px solid #dfe7ef;
    border-radius: 6px;
}

#doc-item-toast-container {
    display: flex;
}