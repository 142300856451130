.import-status-window,
.import-status-window-min,
.import-status-window-hidden {
    position: fixed;
    bottom: 0px;
    margin: 16px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 30vw;
    height: 45vh;
    z-index: 9999;
    transition: transform 0.3s ease-in-out;
}

.import-status-window {
    transform: translateY(0);
}
  
.import-status-window-min {
    transform: translateY(88%);
}

.import-status-window-hidden {
    transform: translateY(1000px);
}

.import-status-content {
    padding: 10px 20px;
}

#import-status-header > p {
    margin: 0px;
    font-weight: bold;
    color: #043d75;
    font-family: Lato, Helvetica, sans-serif;
    font-size: 20px;
}

#import-status-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#import-timer {
    background-color: #F0F1F2;
}

#import-timer > p {
    margin: 0px;
    padding: 5px 10px;
    color: #464E54;
}

#import-list {
    margin-top: 10px;
}

.progress-list-item {
    display: flex;
    border-bottom: 1px solid #E8EAEB;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    height: 6vh;
}

.progress-list-item-left-imp {
    display: flex;
    gap: 20px;
}

.progress-list-item-left-imp > p {
    margin: 0px;
    color: #043d75;
    font-family: Lato, Helvetica, sans-serif;
}

.progress-list-item-left-scan {
    display: flex;
    flex-direction: column;
    padding-top: 0px 2px;
}

.progress-list-item-left-scan > div > p {
    margin: 0px;
    color: #043d75;
    font-family: Lato, Helvetica, sans-serif;
}

.progress-list-item-left-scan-progress {
    display: flex;
    gap: 20px;
}

.progress-list-item-right {
    display: flex;
    gap: 2px;
    align-items: center;
}

.progress-list-item-right > p:hover {
    cursor: pointer;
    transition: color 0.3s;
}

.progress-list-item-right > p:hover {
    color: red;
}

.progress-list-item-right > p {
    color: #043d75;
}

#total-scanned > p {
    margin: 0px;
    font-weight: bold;
}

#total-scanned {
    padding: 10px 5px;
}

.slide-enter {
    transform: translateX(100%);
}

.slide-enter-active {
    transform: translateX(0%);
    transition: transform 300ms;
}

.slide-exit {
    transform: translateX(0%);
}

.slide-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
}

.info-icon {
    cursor: pointer;
}

#progress-buttons {
    display: flex;
}

.import-list-scroll {
    max-height: 33vh;
    overflow-y: auto;
}
