#Overview {
    color: #043d75;
    font-size: 1rem;
    font-weight: normal;
}

#histCards {
    display: flex;
    flex-direction: row;
    gap: 15px;
    flex-wrap: wrap;
}

#histSection {
    margin-bottom: 25px;
}

#expandButton {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding-left: 40%;
}

#project-selection-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.proj-select-form-item {
    display: flex;
    flex-direction: column;
}

#proj-client-card {
    width: 40vw;
    margin-left: 2px;
    margin-bottom: 2px;
}

@media (max-width: 1200px) {
    #proj-client-card {
        width: 60vw;
    }
}

#current-proj-selection {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

#proj-select-items {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sc-text {
    margin: 5px 0px;
}

.sc-text-header {
    font-weight: bold;
    font-size: 18px;
}

.create-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.create-buttons > button {
    flex-basis: 48%;
}

.select-header {
    align-items: center;
    display: flex;
    gap: 10px;
}

.selected-item {
    width: 25%;
}

#no-doc-text {
    padding: 0rem 1rem 1rem 1.25rem;
}