.header {
    color: #043d75;
}

#header-section {
    display: flex;
    justify-content: center;
}

#card-section {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 25px;
    margin-bottom: 30px;
}

#card-section > div {
    flex-basis: 40%;
}

#card-section > div > .p-card-body {
    padding: 0px;
}

#support-ticket {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

#support-ticket > span > input, #support-ticket > span > textarea {
    width: 100%;
}