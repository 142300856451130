.result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 8px;
    padding: 0px 20px;
}

.result-item:hover * {
    color: white;
}

.result-item:hover{
    background-color: #043d75;
    cursor: pointer;
}

.result-desc {
    display: flex;
    align-items: center;
    gap: 15px;
}

.search-result-icon, .search-result-text {
    font-size: 18px;
    color: #043d75;
}

.result-underline.p-divider.p-divider-horizontal {
    margin: 5px !important;
}