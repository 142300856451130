#top-toggle {
    display: flex;
    justify-content: center;
}

#account-page-container {
    display: flex;
    justify-content: space-between;
}

h1 {
    color:#043d75
}

#account-column-1 {
    flex-basis: 35%;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

#account-column-2 {
    flex-basis: 60%;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

#user-update-buttons {
    display: flex;
    gap: 15px;
}

.update-sect {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#oldpass > input, #newpass > input, #connewpass > input {
    width: 100%;
}

.assigned-projects > .p-datatable-wrapper {
    max-height: 55vh;
}