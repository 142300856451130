#custom-panel-menu {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-left: 15px;
}

#import-button {
  color: #043d75;

}

.p-button.p-button-outlined.import-button{
  background-color: #EBF0F5 !important;
}

.p-dialog-header {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem !important;
}

.import-p {
  margin-bottom: 15px !important;
}

#panel-page {
  display: flex;
}

.import-url-p {
  font-weight: bold;
  font-size: 18px;
  color: #708DA9;
}

#or-divider {
  display: flex;
  gap: 15px;
}

#att-click {
  text-decoration: underline;
  color: #043d75;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.up-cat-headers {
  font-weight: bold;
  font-size: 18px;
}

.up-chip-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

#go-back-text {
  margin-top: 50px;
}

.panel-skeleton {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.flashing-button {
  animation: flash 1s infinite;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
  color: #666;
}

.file-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-info {
  display: flex;
  gap: 15px;
}

.p-fileupload-content {
  overflow: auto;
  height: 20rem;
}

#folder-info-input {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.folder-info-p {
  margin-top: 0px;
}

.folder-sect {
  display: flex;
  flex-direction: column;
}

.panel-menu-class {
  overflow-y: auto;
  max-height: 75vh;
}