#activity-menu {
    display: flex;
    align-items: center;
    gap: 15px;
}

.activity-filter-item {
    margin: 10px 0px;
    cursor: pointer;
    position: relative;
}
  
.activity-filter-item::before {
    content: "";
    position: absolute;
    left: 0;
    right: 100%;
    bottom: -2px;
    border-bottom: 2px solid transparent;
    transition: right 0.4s ease;
}
  
.activity-filter-item:hover::before {
    right: 0;
    border-color: #043d75;
}

#activity-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#doc-item-toast-grey {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #EFF3F8;
    border-radius: 8px;
    padding: 0px 20px;
}

#activity-tab-view > .p-tabview-nav-container {
    overflow-y: hidden;
}

#activity-tab-view {
    overflow-y: unset;
    overflow-x: unset;
}

#activity-tab-view > .p-tabview-panels > div {
    max-height: calc(100vh - 290px);
    overflow: auto;
}

@media (max-width: 1200px) {
    #activity-tab-view > .p-tabview-panels > div {
        max-height: calc(100vh - 300px);
    }
}

@media (max-width: 1100px) {
    #activity-tab-view > .p-tabview-panels > div {
        max-height: calc(100vh - 310px);
    }
}