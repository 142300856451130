.activity-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    margin-left: 15px;
}

.activity-item-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-left: 15px;
    gap: 10px;
}

.linked-activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 10px 0px;
}

.linked-activity > p {
    margin: 0px;
}

.activity-link {
    text-decoration: underline;
    font-weight: bold;
    color: #043d75;
}

.typeText {
    display: flex;
    padding: 10px;
    border-radius: 5px;
}

.typeText > p {
    margin: 0px;
    font-weight: bold;
    color: black;
}

.descSect {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 3px;
}

.descSect > p {
    margin: 10px 0px;
}

.activity-vert {
    margin: 0 0 !important;
    padding: 0 0 !important;
}

.p-divider.p-divider-horizontal.activity-horz {
    margin: 0 0 !important;
}

.activity-plus-check {
    display: flex;
    gap: 30px;
    padding: 10px 0px;
}

.all-activity:hover {
    background-color: #EFF3F8;
}

.all-activity {
    transition: background-color 0.1s ease-in-out;
}