#import-doc-window {
    margin: 16px 0px 16px 16px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    /* width: 25vw; */
    height: 75vh;
}

#import-doc-window-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 2px 20px;
    align-items: center;
}

#import-doc-window-header > p {
    margin: 0px;
    font-weight: bold;
    color: #043d75;
    font-family: Lato, Helvetica, sans-serif;
    font-size: 19px;
}

#import-window-tab {
    padding: 0px 20px;
}

.import-window-item-first {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
}

.import-window-header {
    font-weight: bold;
    padding: 0px 20px;
}

.import-window-header > p {
    margin-top: 0px;
}

.import-window-item {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
}

.import-window-item > p {
    margin-top: 0px;
}

.item-bold {
    font-weight: bold;
}

.import-window-activity-item {
    padding: 15px 20px 10px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.import-window-activity-item > p {
    margin: 0px;
}

.activity-desc {
    display: flex;
    align-items: center;
    gap: 10px;
}